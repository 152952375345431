<template>
	<PageLayout :title="$t('kyc-documents.title')" :subtitle="$t('kyc-documents.subtitle')">
		<div class="cashier-box">
			<div class="cashier-box__intro">
				<p>{{ $t('kyc-documents.intro.message') }}</p>
				<p>{{ $t('kyc-documents.intro.order') }}</p>
				<p>
					<img class="check-icon" alt="check" src="../../../assets/check.svg" />
					<span v-html="$t('kyc-documents.intro.identity')" />
				</p>
				<p>
					<img class="check-icon" alt="check" src="../../../assets/check.svg" />
					<span v-html="$t('kyc-documents.intro.address')" />
				</p>
			</div>
			<div class="cashier-box__upload">
				<vue-dropzone
					id="dropzone"
					ref="myVueDropzone"
					:options="$options.dropzoneOptions"
					:use-custom-slot="true"
					@vdropzone-success-multiple="vsuccessMuliple"
					@vdropzone-error="failed"
				>
					<div class="dropzone-custom-content">
						<h3 class="dropzone-custom-title">
							{{ $t('kyc-documents.drop-zone.title') }}
						</h3>
						<div class="subtitle" v-html="$t('kyc-documents.drop-zone.sub-title')" />
					</div>
				</vue-dropzone>
			</div>
			<button type="button" class="button primary-button cashier-box__btn" @click="uploadFiles">
				{{ $t('kyc-documents.upload') }}
			</button>
		</div>
	</PageLayout>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import PageLayout from '@/components/page-layout/PageLayout';

export default {
	name: 'KYCDocuments',
	components: {
		PageLayout,
		vueDropzone: vue2Dropzone,
	},
	created() {
		this.$options.dropzoneOptions = {
			autoProcessQueue: false,
			enqueueForUpload: false,
			url: `${this.backendUrl}/documents/upload`,
			headers: {
				authorization: `Bearer ${this.$store.getters.getAuthToken}`,
			},
			thumbnailWidth: 200,
			addRemoveLinks: true,
			acceptedFiles: 'image/*,.pdf',
			maxFiles: 2,
			paramName() {
				return 'file';
			},
			uploadMultiple: true,
			parallelUploads: 2,
		};
	},
	methods: {
		uploadFiles() {
			this.$refs.myVueDropzone.processQueue();
		},
		failed(_file, _message, xhr) {
			if (xhr) {
				const { message } = JSON.parse(xhr.response).error;
				const elements = document.querySelectorAll('.dz-error-message span');
				const lastElement = elements[elements.length - 1];
				lastElement.textContent = message;
			}
		},
		vsuccessMuliple(file, response) {
			this.$router.push({
				path: '/documents/upload/confirmation',
				query: {
					status: response.success ? 'success' : 'error',
				},
			});
		},
	},
};
</script>

<style scoped lang="scss">
@import 'src/assets/style/_variables';
@import 'src/assets/style/mixin';

.cashier-box {
	padding: $mobile-form-padding;
	display: flex;
	flex-direction: column;
	@include min-screen($md + 1) {
		padding: $form-padding;
	}

	&__intro {
		font-size: $title;
		@include min-screen($md + 1) {
			font-size: $heading;
		}

		p {
			text-align: left;
			font-size: $label;
			line-height: 1.5;
			margin: 0 0 15px 0;

			&:last-child {
				margin-bottom: 0;
			}

			.check-icon {
				width: 14px;
				height: 14px;
				margin-right: 6px;
			}
		}
	}

	&__upload {
		position: relative;
		min-height: 132px;
		background: $dropzone-upload-bg;
		margin: 25px auto;
		width: 100%;
		height: auto;
	}

	&__btn {
		@include max-screen($md) {
			max-width: 100%;
		}
	}
}

::v-deep .dropzone {
	min-height: 132px;
	height: 100%;
	background: $dropzone-upload-bg;
	border: 1px dashed $dropzone-border;
	box-sizing: border-box;
	border-radius: 5px;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;

	@include max-screen($md) {
		align-items: center;
	}

	@include min-screen($md + 1) {
		flex-direction: row;
		justify-content: flex-start;
	}

	&-custom-content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		width: calc(100% - 50px);
		margin: 0 auto;
	}

	&-custom-title {
		margin-top: 0;
		color: $white;
		font-weight: $bold;
		font-size: $title;
		line-height: 1.35;
		margin-bottom: 20px;
	}

	&-custom-subtitle {
		color: $regent-gray;
		font-size: $label;
		line-height: 14px;
	}

	.dz-image-preview {
		margin: 5px;
	}

	.dz-image {
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	.dz-preview {
		max-width: 100%;
		border: 1px solid black;
		margin: 5px;
		height: 150px;
		width: 150px;

		.dz-error-message,
		.dz-error {
			opacity: 1 !important;
			top: 0 !important;
			border-radius: 0 !important;
		}
	}
}

::v-deep .vue-dropzone > .dz-preview .dz-success-mark,
::v-deep .dropzone .dz-preview.dz-error .dz-error-mark,
::v-deep .dropzone .dz-preview:not(.dz-processing) .dz-progress {
	display: none !important;
}

::v-deep .vue-dropzone:hover {
	background: $dropzone-hover;
}
</style>
